<template>
  <div data-id="breadcrumbs" class="bg-bgr">
    <OnDevices rule=">=md" show-class="flex">
      <BreadcrumbsDesktop :items="items">
        <template v-for="(_, name) in $slots" #[name]>
          <slot :name="name" />
        </template>
      </BreadcrumbsDesktop>
    </OnDevices>

    <OnDevices rule="<md">
      <BreadcrumbsMobile :items="items" />
    </OnDevices>
  </div>
</template>

<script lang="ts">
import BreadcrumbsDesktop from '~/components/basic/breadcrumbs/BreadcrumbsDesktop.vue'
import BreadcrumbsMobile from '~/components/basic/breadcrumbs/BreadcrumbsMobile.vue'

export default defineComponent({
  name: 'Breadcrumbs',

  components: {
    BreadcrumbsDesktop,
    BreadcrumbsMobile,
  },

  props: {
    items: { type: Array, required: true },
  },
})
</script>
